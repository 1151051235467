const keycodes = {
  DOWN: '40',
  END: '35',
  ENTER: '13',
  HOME: '36',
  LEFT: '37',
  RIGHT: '39',
  SPACE: '32',
  UP: '38',
};

export default keycodes;
